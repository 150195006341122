/* global google */
/**
 * mapsHelper.js 
 * v1.0.0
 * 
 * Helper utility for managing the loading of Google Maps in a compiled JS 
 * environment as well as creating maps that observe the common practices of 
 * Automatit website development.
 * 
 * Exports - getMapsHelper 
 * A function for creating an instance of the maps helper object. Instanced 
 * to facilitate potentially multiple maps on a single page.
 * @return mapsHelper
 * 
 * <Object> mapsHelper
 * A collection of functions for abstracting the process of creating a Google Map
 * consistent with common Automatit website practices.
 * 
 * mapsHelper.ready() - Returns a Promise that resolves when GoogleMaps and RichMarker 
 * are finished loading and ready for use. Further calls to the mapsHelper should
 * await the resolution of this promise.
 * @return Promise<void>
 * 
 * mapsHelper.createMap() - Accepts a named property map (object) that corresponds to 
 * required and optional arguments. These arguments are:
 *  - element : the HTMLElement to contain the map
 *  - locationElementSelector : css selector (string) to match elements which can resolve to marker data
 *  - markerReducer : function(HTMLElement) a function which reduces an element to an object {lat,lng,content}
 *  - mouseIn : function(HTMLElement, MarkerData) a function to bind to the mouseenter event for elements matched by locationElementSelector
 *  - mouseOut : function(HTMLElement, MarkerData) a function to bind to the mouseleave event for elements matched by locationElementSelector
 *  - useRichmarker : Boolean declaring whether to use RichMarker objects for locations
 * @param Object - Named property map of arguments
 * @return google.maps.map - the created map
 * 
 * mapsHelper.getMarker(HTMLElement) - returns the MarkerData associated with a specific HTMLElement
 * @return MarkerData
 * 
 * MarkerData<Object>
 * MarkerData.marker - the actual Marker object passed to the map.
 * MarkerData.element - the element created and passed to the marker as content (RichMarker only)
 */


import { GoogleMapsKey } from "../globals.js";

if(! GoogleMapsKey){
	throw new Error("mapsHelper requires a valid GoogleMapsKey be defined");
}

const mapsSrc = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsKey}&libraries=places`;
const richmarkerSrc = `https://shared.automatit.net/maps/richmarker-compiled.js`;


/**
 * Loads the RichMarker script 
 * @return Promise<void>
 */
const loadRichmarker = async () => {
	const promise = new Promise((res) => {
		const scriptTag = document.createElement("script");
		scriptTag.src = richmarkerSrc;
		scriptTag.addEventListener('load', res);
		document.body.appendChild(scriptTag);
	});
	return promise;
};

/**
 * Loads the Google Maps API. Resolves the promise on callback
 * @return Promise<void>
 */
const loadMap = async () => {
	let res;
	let prom = new Promise((resolve) => {
		res = resolve;
	});

	const tag = document.createElement("script");
	tag.src = `${mapsSrc}&callback=aiGoogleMapsInit`;

	document.body.appendChild(tag);

	window.aiGoogleMapsInit = () => {
		loadRichmarker().then(()=>{
			res(window.google);
		});
	};
	return prom;
};

const _ready = loadMap();

/**
 * getMapsHelper - A factory for retrieving an instance of the mapsHelper
 * 
 * @return mapsHelper instance
 */
export const getMapsHelper = () => {
	const _markers = new WeakMap();

	/**
	 * Helper object encapsulating various maps functionality.
	 * 
	 * mapsHelper.ready = function() - returns a promise for when google maps and dependencies have been resolved.
	 * mapsHelper.getMarker = function(HTMLElement) - returns MarkerData associated with a specific HTMLElment
	 * mapsHelper.createMap = function(options = {}) - a function for creating a map.
	 * 
	 * createMapOptions:
	 *  - element : The HTMLElement to bind the map to
	 *  - locationElementSelector : A querySelector (string) mapping to the elements which can be reduced
	 *      to an object containing the location marker data (lat,lng,content)
	 *  - markerReducer : A function which accepts an HTMLElement as an argument and returns an object representing
	 *      the marker data {lat,lng,content}
	 *  - mouseIn : A function which binds to the mouseenter event of elements selected by locationElementSelector.
	 *  - mouseOut : A function which binds to the mouseleave event of elements selected by locationElementSelector.
	 *  - useRichmarker : Boolean value representing whether or not to use RichMarker objects
	 */
	const mapsHelper = {
		ready: () => _ready,
		createMap: ({
			element,
			locationElementSelector,
			useRichmarker = false,
			markerReducer = (el) => ({
				lat: +el.getAttribute('lat'),
				lng: +el.getAttribute('lng'),
			}),
			mouseIn = (el, marker) => {
				el.classList.add('hover');
				if(marker.element){
					marker.element.classList.add('hover');
				}
			},
			mouseOut = (el, marker) => {
				el.classList.remove('hover');
				if(marker.element){
					marker.element.classList.remove('hover');
				}
			}
		} = {}) => {
			// check that we have location data
			const matchedElements = [...document.querySelectorAll(locationElementSelector)];
			if(matchedElements.length === 0){ 
				throw new Error("No Location Data");
			}
			const markerData = matchedElements.map(markerReducer);

			const firstData = markerData[0];
			//initialize the map
			const map = new google.maps.Map(element, {
				center: new google.maps.LatLng(firstData.lat, firstData.lng), //initialize with first location position
				zoom: 15
			});

			const bounds = new google.maps.LatLngBounds();//initialize variable for collection map bounds
			
			for(let i = 0; i < matchedElements.length; i++){
				//iterate over all elements matched by locationElementSelector and add them to the map
				const node = matchedElements[i];
				const locData = markerData[i];
				
				const markerClass = useRichmarker ? window.RichMarker : google.maps.Marker;//decide which marker class to use
				//create the marker object
				const latlng = new google.maps.LatLng(locData.lat, locData.lng);
				let content = locData.content;
				if(! content){
					content = [...document.querySelectorAll(locationElementSelector)].indexOf(node) + 1;
					if(useRichmarker){
						content = `<a href='#' class='mappin'>${content}</a>`;
					}
				}
				const marker = new markerClass({
					position: latlng,
					map: map,
					content: useRichmarker ? parseHTML(content) : '',
					label: !useRichmarker ? content : undefined,
				});

				//extend the bounding box
				bounds.extend(marker.position);

				//persist the marker data to be retrieved later
				_markers.set(node, {
					marker: marker,
					element: marker.getContent ? marker.getContent() : null
				});

				//bind event handlers
				if(mouseIn){    
					node.addEventListener('mouseenter', () => {
						mouseIn.call(null, node, _markers.get(node));
					});
				}
				if(mouseOut){
					node.addEventListener('mouseleave', () => {
						mouseOut.call(null, node, _markers.get(node));
					});
				}
			}
			map.fitBounds(bounds);
			return {map, bounds};
		},
		getMarker: (node) => _markers.get(node)
	};
	return mapsHelper;
};


const parseHTML = (str) => {
	const template = document.createElement('div');
	template.innerHTML = str;
	return template.children[0];
};