import { getMapsHelper } from './util/mapsHelper';

export const initFacNearbyMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const {map: theMap, bounds} = mapHelper.createMap({
				element: document.querySelector('#facility_nearby_map'),
				locationElementSelector: '.facility_card',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<a class="map_pin" href="${el.getAttribute('link')}">
								<img src="/static/images/icons/map_pin.svg" alt="Visit facility" />
								<span>${el.getAttribute('count')}</span>
							</a>
						`
					};
				},
			});

			theMap.addListener('bounds_changed', () => {
				if(window.triggerResize) {
					theMap.fitBounds(bounds);
					window.triggerResize = false;
				}
			});
		});
};

export const initRateFilters = () => {

	const rows = document.querySelectorAll('.unit_row');

	var noUnitsMsg = document.querySelector('.no_units_message');
	
	document.querySelector('#rates_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');
		var unitShown = 0;
		
		if(clickedFilter) {
			if(clickedFilter.classList.contains('filter')) {
				clickedFilter.toggleAttribute('active');
			}
		}

		rows.forEach(i => {
			i.style.display = 'none';
			const unitFeats = i.getAttribute('feat-string').split('|');

			const activeFilters = document.querySelectorAll(`#rates_filters button[active]`).length;
			let numMatchedFilter = 0;

			unitFeats.forEach(feat => {
				if(document.querySelector(`#rates_filters button[filter="${feat}"][active]`)) {
					numMatchedFilter++;
				}
			});

			if(numMatchedFilter === activeFilters){
				i.style.display = 'flex';
				unitShown++;
			}

			if(unitShown === 0){
				noUnitsMsg.style.display = '';
			}else {
				noUnitsMsg.style.display = 'none';
			}
			console.log(unitShown);
			
			if(!document.querySelector('#rates_filters button[active]')) {
				i.style.display = 'flex';
				unitShown++;
			}
			
		});
	});
};