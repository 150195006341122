import { AutomatitCarousel } from './automatit_carousel.m';

// Home carousel
export const initHomeCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero_caro'),
		showDots: false,
		intervalTiming: 0,
		useChevrons: false,
		autoVerticalCentering: false,
	});
};