/* eslint-disable no-undef */
import { getMapsHelper } from './util/mapsHelper';

export const initMultiMap = () => {
	const mapHelper = getMapsHelper();
	
	mapHelper.ready()
		.then(() => {
			const {map: theMap, bounds} = mapHelper.createMap({

				element: document.querySelector('#multi_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<a class="map_pin" href="${el.getAttribute('link')}">
								<img src="/static/images/icons/map_ic.svg" alt="Visit facility" />
							</a>
						`
					};
				},
			});

			
			theMap.fitBounds(bounds);
			let listener;
			listener = google.maps.event.addListenerOnce(theMap, "idle", function() { 
				if (theMap.getZoom() > 10) theMap.setZoom(18); 
				google.maps.event.removeListener(listener); 
			});
		});
};
